.title-container {
  display: flex;
  flex-direction: column;
  height: 20vh;
}

.landing-title {
  color: aqua;
}

.sub-title {
  color: azure;
}
