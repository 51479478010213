.form {
  margin-bottom: 10rem;
  color: white;
  font-size: 1rem;
}
.form-group {
  margin-top: 1rem;
}
.submit-button {
  margin-top: 1rem;
}
